import { ErrorView } from '@app/views/error';
import { LoadingView } from '@app/views/loading';
import { FC, PropsWithChildren, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

export const Boundary: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ErrorBoundary fallbackRender={({ resetErrorBoundary, error }) => <ErrorView error={error} onRetry={resetErrorBoundary} />}>
      <Suspense fallback={<LoadingView />}>{children}</Suspense>
    </ErrorBoundary>
  );
};
