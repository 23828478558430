import { createGlobalStyle } from 'styled-components';
import { font } from './common';
import { toastifyCss } from './toastify';

export const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: ${({ theme }) => theme.colors.grayLight};
    ${font()}
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  body.modalactive {
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
  }

  ${toastifyCss}
`;
