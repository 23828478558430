import { ApolloError, GraphQLErrors } from '@apollo/client/errors';
import { CODES, MESSAGES } from '@app/constants/error-codes.constant';
import { toast } from 'react-toastify';

export const getErrorCode = (error?: { graphQLErrors?: GraphQLErrors }) => {
  const code = error?.graphQLErrors?.[0]?.extensions?.code as CODES | undefined;
  return code || 'UNKNOWN';
};

type CustomCodeHandler = string | (() => void);
type CustomCodeHandlers = Partial<Record<CODES, CustomCodeHandler>>;

export const getErrorHandler = (codes?: CustomCodeHandlers) => (error: ApolloError) => {
  const errorCode = getErrorCode(error);
  const customHandler = codes?.[errorCode];
  if (typeof customHandler === 'function') return customHandler();
  if (typeof customHandler === 'string') return toast.error(customHandler);
  toast.error(MESSAGES[errorCode] || 'An unknown error has occurred');
};
