type SizeOptions = 'padding';

export const sizes = {
  small: {
    padding: '8px 15px',
  },
  primary: {
    padding: '14px 40px',
  },
  large: {
    padding: '17px 60px',
  },
} satisfies Record<string, Record<SizeOptions, string>>;

export type Size = keyof typeof sizes;
