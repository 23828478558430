import { ButtonHTMLAttributes, FC } from 'react';
import { Kind } from './config/button-kinds.config';
import { Size } from './config/button-sizes.config';
import { Icon, IconName } from '@app/components/icon';
import { ButtonWrapper } from './button.styles';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  kind?: Kind;
  size?: Size;
  icon?: IconName;
}

export const Button: FC<ButtonProps> = ({ children, icon, isLoading, kind, size, ...rest }) => (
  <ButtonWrapper type="button" $isLoading={isLoading} $kind={kind} $size={size} {...rest}>
    {icon && <Icon name={icon} />}
    {children}
  </ButtonWrapper>
);
