export type BreakpointType = 'sm' | 'md' | 'lg' | 'xl';

export const breakpoints: Record<BreakpointType, string> = {
  sm: '500px',
  md: '720px',
  lg: '1000px',
  xl: '1500px',
};

export const breakpointsMedia: Record<BreakpointType, string> = {
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`,
};
