import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  colors: {
    primary: '#7B08FF',
    secondary: '#CFFE3D',
    turquoise: '#4DDFF3',
    grayLight: '#F2F2F2',
    gray: '#BCC2C5',
    grayDark: '#868B8C',
    danger: '#F6384D',
    warning: '#FAAD14',
    success: '#52C41A',
    white: '#FFFFFF',
    black: '#000000',
  },
  fontSizes: {
    xxs: '11px',
    xs: '14px',
    s: '16px',
    base: '20px',
    lg: '26px',
    xl: '32px',
    xxl: '42px',
    '3xl': '50px',
    '4xl': '64px',
  },
  shadows: {
    base: '0 3px 7px rgba(0, 0, 0, 0.15)',
  },
};
