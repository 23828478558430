import styled, { css } from 'styled-components';
import { afterSpinner, font } from '@app/styles/common';
import { breakpointsMedia } from '@app/styles/breakpoints';
import { Kind, kinds } from './config/button-kinds.config';
import { Size, sizes } from './config/button-sizes.config';

interface ButtonWrapperProps {
  $isLoading?: boolean;
  $kind?: Kind;
  $size?: Size;
  disabled?: boolean;
}

export const ButtonWrapper = styled.button.attrs<ButtonWrapperProps>(({ $isLoading, disabled }) => ({
  disabled: disabled || $isLoading,
}))<ButtonWrapperProps>`
  ${font()}
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: 24px;
  border: 0;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }

  ${breakpointsMedia.md} {
    padding: ${({ $size = 'primary' }) => sizes[$size].padding};
  }

  ${({ theme, $kind = 'primary' }) => css<ButtonWrapperProps>`
    background-color: ${theme.colors[kinds[$kind].background]};
    color: ${theme.colors[kinds[$kind].color]};
    & svg * {
      fill: ${theme.colors[kinds[$kind].color]};
    }
  `}

  ${({ $isLoading, theme, $kind = 'primary' }) =>
    $isLoading &&
    css<ButtonWrapperProps>`
      color: transparent;
      position: relative;
      ${afterSpinner(15, theme.colors[kinds[$kind].color])}
      & * {
        opacity: 0;
      }
    `}
`;
