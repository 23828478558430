import { combineUrl } from '@app/utils/urls.util';

export const API_URL = import.meta.env.DEV ? `/api` : import.meta.env.VITE_API_URL;

export const API_GRAPHQL_URL = import.meta.env.DEV ? '/graphql' : combineUrl(import.meta.env.VITE_API_URL, '/graphql');
export const API_GRAPHQL_AUTH_URL = `${API_GRAPHQL_URL}/auth`;

export const CUSTOMER_URL = import.meta.env.VITE_CUSTOMER_URL;
export const CAPTURE_URL = import.meta.env.VITE_CAPTURE_URL;

export const IS_IOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
