/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation userRefresh {\n    userRefresh {\n      accessToken\n    }\n  }\n": types.UserRefreshDocument,
    "\n  query captureWithGallery($captureId: UUID!) {\n    capture(captureId: $captureId) {\n      id\n      shortId\n      uploadFilename\n      status\n      file {\n        path\n      }\n      preview {\n        path\n      }\n      createdAt\n      gallery {\n        id\n        name\n        slug\n      }\n    }\n  }\n": types.CaptureWithGalleryDocument,
    "\n  query customerGallery($galleryId: UUID!) {\n    gallery(galleryId: $galleryId) {\n      id\n      name\n      slug\n      endsAt\n    }\n  }\n": types.CustomerGalleryDocument,
    "\n  query capturesCount($filters: CapturesFiltersInput!) {\n    count: capturesSize(filters: $filters)\n  }\n": types.CapturesCountDocument,
    "\n  query customerCaptures($limit: Int!, $cursor: String, $filters: CapturesFiltersInput) {\n    captures: seekCaptures(limit: $limit, cursor: $cursor, filters: $filters) {\n      cursor\n      items {\n        id\n        shortId\n        uploadFilename\n        status\n        file {\n          path\n        }\n        preview {\n          path\n        }\n        thumbnail {\n          path\n        }\n      }\n    }\n  }\n": types.CustomerCapturesDocument,
    "\n  mutation customerSignIn($galleryId: UUID!, $password: String!) {\n    customerSignin(galleryId: $galleryId, password: $password) {\n      customerToken\n    }\n  }\n": types.CustomerSignInDocument,
    "\n  query captures($page: Int!, $limit: Int!, $filters: CapturesFiltersInput) {\n    captures: pageCaptures(page: $page, limit: $limit, filters: $filters) {\n      count\n      pagesCount\n      items {\n        id\n        shortId\n        uploadFilename\n        status\n        deletedAt\n        file {\n          path\n        }\n        preview {\n          path\n        }\n        thumbnail {\n          path\n        }\n      }\n    }\n  }\n": types.CapturesDocument,
    "\n  query capture($captureId: UUID!) {\n    capture(captureId: $captureId, withDeleted: true) {\n      id\n      shortId\n      uploadFilename\n      status\n      file {\n        path\n      }\n      preview {\n        path\n      }\n      createdAt\n      metadata\n    }\n  }\n": types.CaptureDocument,
    "\n  mutation generateCapturesZipUrl($filename: String!, $filters: CapturesFiltersInput!) {\n    url: generateCapturesZipUrl(filename: $filename, filters: $filters)\n  }\n": types.GenerateCapturesZipUrlDocument,
    "\n  query capturesSize($filters: CapturesFiltersInput!) {\n    size: capturesSize(filters: $filters)\n  }\n": types.CapturesSizeDocument,
    "\n  mutation deleteCapture($captureId: UUID!) {\n    deleteCapture(captureId: $captureId)\n  }\n": types.DeleteCaptureDocument,
    "\n  mutation restoreCapture($captureId: UUID!) {\n    restoreCapture(captureId: $captureId)\n  }\n": types.RestoreCaptureDocument,
    "\n  mutation generateCaptureDownloadUrl($captureId: UUID!) {\n    url: generateCaptureDownloadUrl(captureId: $captureId)\n  }\n": types.GenerateCaptureDownloadUrlDocument,
    "\n  mutation createGalleryType($data: CreateGalleryTypeInput!) {\n    galleryType: createGalleryType(data: $data) {\n      id\n    }\n  }\n": types.CreateGalleryTypeDocument,
    "\n  query galleryType($galleryTypeId: UUID!) {\n    galleryType(galleryTypeId: $galleryTypeId) {\n      id\n      name\n    }\n  }\n": types.GalleryTypeDocument,
    "\n  mutation updateGalleryType($galleryTypeId: UUID!, $data: UpdateGalleryTypeInput!) {\n    updateGalleryType(galleryTypeId: $galleryTypeId, data: $data) {\n      id\n      name\n    }\n  }\n": types.UpdateGalleryTypeDocument,
    "\n  query galleryTypes {\n    galleryTypes {\n      id\n      name\n    }\n  }\n": types.GalleryTypesDocument,
    "\n  mutation deleteGalleryType($galleryTypeId: UUID!) {\n    deleteGalleryType(galleryTypeId: $galleryTypeId)\n  }\n": types.DeleteGalleryTypeDocument,
    "\n  mutation duplicateGallery($galleryId: UUID!) {\n    newGallery: duplicateGallery(galleryId: $galleryId) {\n      id\n    }\n  }\n": types.DuplicateGalleryDocument,
    "\n  query galleries($page: Int!, $limit: Int!, $filters: GalleriesFiltersInput) {\n    galleries(page: $page, limit: $limit, filters: $filters) {\n      count\n      pagesCount\n      items {\n        id\n        name\n        startsAt\n        endsAt\n        capturesCount\n        removalDate\n        type {\n          name\n        }\n      }\n    }\n  }\n": types.GalleriesDocument,
    "\n  query galleryStatus($galleryId: UUID!) {\n    messagesSent: messagesCount(filters: { galleryId: $galleryId, status: SENT })\n    messagesError: messagesCount(filters: { galleryId: $galleryId, status: ERROR })\n    messagesWaiting: messagesCount(filters: { galleryId: $galleryId, status: WAITING })\n    capturesWaiting: capturesCount(filters: { galleryId: $galleryId, status: CREATED })\n    capturesError: capturesCount(filters: { galleryId: $galleryId, status: ERROR })\n    capturesProcessed: capturesCount(filters: { galleryId: $galleryId, status: PROCESSED })\n    captures: pageCaptures(page: 1, limit: 3, filters: { galleryId: $galleryId, status: PROCESSED }) {\n      items {\n        id\n        shortId\n        uploadFilename\n        status\n        deletedAt\n        file {\n          path\n        }\n        preview {\n          path\n        }\n        thumbnail {\n          path\n        }\n      }\n    }\n  }\n": types.GalleryStatusDocument,
    "\n  mutation deleteGallery($galleryId: UUID!) {\n    deleteGallery(galleryId: $galleryId)\n  }\n": types.DeleteGalleryDocument,
    "\n  mutation restoreGallery($galleryId: UUID!) {\n    restoreGallery(galleryId: $galleryId)\n  }\n": types.RestoreGalleryDocument,
    "\n  query galleryId($gallerySlug: String!) {\n    galleryId(gallerySlug: $gallerySlug)\n  }\n": types.GalleryIdDocument,
    "\n  query messages($page: Int!, $limit: Int!, $filters: MessagesFiltersInput) {\n    messages(page: $page, limit: $limit, filters: $filters) {\n      count\n      pagesCount\n      items {\n        id\n        contact\n        type\n        status\n        sentAt\n        errorMessage\n      }\n    }\n  }\n": types.MessagesDocument,
    "\n  query messagesWithCapture($page: Int!, $limit: Int!, $filters: MessagesFiltersInput) {\n    messages(page: $page, limit: $limit, filters: $filters) {\n      count\n      pagesCount\n      items {\n        id\n        contact\n        type\n        status\n        sentAt\n        errorMessage\n        capture {\n          id\n          uploadFilename\n          thumbnail {\n            path\n          }\n          gallery {\n            name\n          }\n        }\n      }\n    }\n  }\n": types.MessagesWithCaptureDocument,
    "\n  query stats($galleryId: UUID!) {\n    stats(galleryId: $galleryId) {\n      gallery {\n        id\n        endsAt\n      }\n      analytics {\n        views {\n          total\n          unique\n        }\n        deviceViews {\n          mobile\n          desktop\n        }\n        actions {\n          shareLink\n          shareFile\n          download\n        }\n        topCaptures {\n          id\n          shortId\n          uploadFilename\n          status\n          file {\n            path\n          }\n          preview {\n            path\n          }\n          thumbnail {\n            path\n          }\n        }\n      }\n      captures {\n        count\n        marketingCount\n        hourlyCount {\n          date\n          count\n        }\n        peopleCount\n      }\n      external {\n        printsCount\n        potentialUsersCount\n      }\n    }\n  }\n": types.StatsDocument,
    "\n  query emailPreview($data: EmailLayoutDataInput!) {\n    emailPreview(data: $data)\n  }\n": types.EmailPreviewDocument,
    "\n  query senderStatus($senderName: String!) {\n    senderStatus(senderName: $senderName)\n  }\n": types.SenderStatusDocument,
    "\n  mutation addSender($senderName: String!) {\n    addSender(senderName: $senderName)\n  }\n": types.AddSenderDocument,
    "\n  query users($filters: UsersFiltersInput) {\n    users(filters: $filters) {\n      id\n      name\n    }\n  }\n": types.UsersDocument,
    "\n  query currentUser {\n    currentUser {\n      id\n      email\n      name\n      role\n    }\n  }\n": types.CurrentUserDocument,
    "\n  mutation userLogout {\n    userLogout\n  }\n": types.UserLogoutDocument,
    "\n  mutation createGallery($data: CreateGalleryInput!) {\n    gallery: createGallery(data: $data) {\n      id\n    }\n  }\n": types.CreateGalleryDocument,
    "\n  mutation saveStatsConfig($galleryId: UUID!, $data: SaveStatsConfigInput!) {\n    saveStatsConfig(galleryId: $galleryId, data: $data)\n  }\n": types.SaveStatsConfigDocument,
    "\n  query galleryBase($galleryId: UUID!) {\n    gallery(galleryId: $galleryId) {\n      id\n      name\n    }\n  }\n": types.GalleryBaseDocument,
    "\n  query galleryConfig($galleryId: UUID!) {\n    gallery(galleryId: $galleryId) {\n      id\n      name\n      slug\n      thumbnailPosition\n      startsAt\n      endsAt\n      galleryTypeId\n      eventManagerId\n      techniciansIds\n      contacts\n    }\n    statsConfig(galleryId: $galleryId) {\n      isPeopleCounterActive\n    }\n  }\n": types.GalleryConfigDocument,
    "\n  mutation updateGallery($galleryId: UUID!, $gallery: UpdateGalleryInput!, $statsConfig: SaveStatsConfigInput!) {\n    updateGallery(galleryId: $galleryId, data: $gallery) {\n      id\n      name\n    }\n    saveStatsConfig(galleryId: $galleryId, data: $statsConfig)\n  }\n": types.UpdateGalleryDocument,
    "\n  query gallery($galleryId: UUID!) {\n    gallery(galleryId: $galleryId) {\n      id\n      slug\n      password\n      startsAt\n      endsAt\n      capturesCount\n    }\n  }\n": types.GalleryDocument,
    "\n  query template($galleryId: UUID!) {\n    template(galleryId: $galleryId) {\n      email {\n        customMjml {\n          id\n          mjml\n        }\n        subject\n        fromName\n        logoUrl\n        logoWidth\n        logoPadding\n        headerText\n        topText\n        bottomText\n        buttonText\n        textColor\n        color\n        bgColor\n        contentBgColor\n        showBottomImg\n      }\n      sms {\n        sender\n        template\n      }\n      qr {\n        logoUrl\n        logoWidth\n        headerText\n        inputText\n        buttonText\n        successText\n        textColor\n        color\n        bgColor\n        showBottomImg\n      }\n    }\n  }\n": types.TemplateDocument,
    "\n  mutation saveTemplate($galleryId: UUID!, $data: SaveTemplateInput!) {\n    saveTemplate(galleryId: $galleryId, data: $data)\n  }\n": types.SaveTemplateDocument,
    "\n  mutation userSignin($email: String!, $password: String!) {\n    userSignin(email: $email, password: $password) {\n      accessToken\n    }\n  }\n": types.UserSigninDocument,
    "\n  query inProgressGalleries {\n    inProgressGalleries {\n      id\n      name\n      startsAt\n      endsAt\n      capturesCount\n      type {\n        name\n      }\n    }\n  }\n": types.InProgressGalleriesDocument,
    "\n  query qrTemplate($galleryId: UUID!) {\n    template(galleryId: $galleryId) {\n      qr {\n        logoUrl\n        logoWidth\n        headerText\n        inputText\n        buttonText\n        successText\n        textColor\n        color\n        bgColor\n        showBottomImg\n      }\n    }\n  }\n": types.QrTemplateDocument,
    "\n  mutation sendEmailByFilename($gallerySlug: String!, $uploadFilename: String!, $email: String!) {\n    sendEmailByFilename(gallerySlug: $gallerySlug, uploadFilename: $uploadFilename, email: $email) {\n      id\n    }\n  }\n": types.SendEmailByFilenameDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation userRefresh {\n    userRefresh {\n      accessToken\n    }\n  }\n"): (typeof documents)["\n  mutation userRefresh {\n    userRefresh {\n      accessToken\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query captureWithGallery($captureId: UUID!) {\n    capture(captureId: $captureId) {\n      id\n      shortId\n      uploadFilename\n      status\n      file {\n        path\n      }\n      preview {\n        path\n      }\n      createdAt\n      gallery {\n        id\n        name\n        slug\n      }\n    }\n  }\n"): (typeof documents)["\n  query captureWithGallery($captureId: UUID!) {\n    capture(captureId: $captureId) {\n      id\n      shortId\n      uploadFilename\n      status\n      file {\n        path\n      }\n      preview {\n        path\n      }\n      createdAt\n      gallery {\n        id\n        name\n        slug\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query customerGallery($galleryId: UUID!) {\n    gallery(galleryId: $galleryId) {\n      id\n      name\n      slug\n      endsAt\n    }\n  }\n"): (typeof documents)["\n  query customerGallery($galleryId: UUID!) {\n    gallery(galleryId: $galleryId) {\n      id\n      name\n      slug\n      endsAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query capturesCount($filters: CapturesFiltersInput!) {\n    count: capturesSize(filters: $filters)\n  }\n"): (typeof documents)["\n  query capturesCount($filters: CapturesFiltersInput!) {\n    count: capturesSize(filters: $filters)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query customerCaptures($limit: Int!, $cursor: String, $filters: CapturesFiltersInput) {\n    captures: seekCaptures(limit: $limit, cursor: $cursor, filters: $filters) {\n      cursor\n      items {\n        id\n        shortId\n        uploadFilename\n        status\n        file {\n          path\n        }\n        preview {\n          path\n        }\n        thumbnail {\n          path\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query customerCaptures($limit: Int!, $cursor: String, $filters: CapturesFiltersInput) {\n    captures: seekCaptures(limit: $limit, cursor: $cursor, filters: $filters) {\n      cursor\n      items {\n        id\n        shortId\n        uploadFilename\n        status\n        file {\n          path\n        }\n        preview {\n          path\n        }\n        thumbnail {\n          path\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation customerSignIn($galleryId: UUID!, $password: String!) {\n    customerSignin(galleryId: $galleryId, password: $password) {\n      customerToken\n    }\n  }\n"): (typeof documents)["\n  mutation customerSignIn($galleryId: UUID!, $password: String!) {\n    customerSignin(galleryId: $galleryId, password: $password) {\n      customerToken\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query captures($page: Int!, $limit: Int!, $filters: CapturesFiltersInput) {\n    captures: pageCaptures(page: $page, limit: $limit, filters: $filters) {\n      count\n      pagesCount\n      items {\n        id\n        shortId\n        uploadFilename\n        status\n        deletedAt\n        file {\n          path\n        }\n        preview {\n          path\n        }\n        thumbnail {\n          path\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query captures($page: Int!, $limit: Int!, $filters: CapturesFiltersInput) {\n    captures: pageCaptures(page: $page, limit: $limit, filters: $filters) {\n      count\n      pagesCount\n      items {\n        id\n        shortId\n        uploadFilename\n        status\n        deletedAt\n        file {\n          path\n        }\n        preview {\n          path\n        }\n        thumbnail {\n          path\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query capture($captureId: UUID!) {\n    capture(captureId: $captureId, withDeleted: true) {\n      id\n      shortId\n      uploadFilename\n      status\n      file {\n        path\n      }\n      preview {\n        path\n      }\n      createdAt\n      metadata\n    }\n  }\n"): (typeof documents)["\n  query capture($captureId: UUID!) {\n    capture(captureId: $captureId, withDeleted: true) {\n      id\n      shortId\n      uploadFilename\n      status\n      file {\n        path\n      }\n      preview {\n        path\n      }\n      createdAt\n      metadata\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation generateCapturesZipUrl($filename: String!, $filters: CapturesFiltersInput!) {\n    url: generateCapturesZipUrl(filename: $filename, filters: $filters)\n  }\n"): (typeof documents)["\n  mutation generateCapturesZipUrl($filename: String!, $filters: CapturesFiltersInput!) {\n    url: generateCapturesZipUrl(filename: $filename, filters: $filters)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query capturesSize($filters: CapturesFiltersInput!) {\n    size: capturesSize(filters: $filters)\n  }\n"): (typeof documents)["\n  query capturesSize($filters: CapturesFiltersInput!) {\n    size: capturesSize(filters: $filters)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteCapture($captureId: UUID!) {\n    deleteCapture(captureId: $captureId)\n  }\n"): (typeof documents)["\n  mutation deleteCapture($captureId: UUID!) {\n    deleteCapture(captureId: $captureId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation restoreCapture($captureId: UUID!) {\n    restoreCapture(captureId: $captureId)\n  }\n"): (typeof documents)["\n  mutation restoreCapture($captureId: UUID!) {\n    restoreCapture(captureId: $captureId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation generateCaptureDownloadUrl($captureId: UUID!) {\n    url: generateCaptureDownloadUrl(captureId: $captureId)\n  }\n"): (typeof documents)["\n  mutation generateCaptureDownloadUrl($captureId: UUID!) {\n    url: generateCaptureDownloadUrl(captureId: $captureId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createGalleryType($data: CreateGalleryTypeInput!) {\n    galleryType: createGalleryType(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation createGalleryType($data: CreateGalleryTypeInput!) {\n    galleryType: createGalleryType(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query galleryType($galleryTypeId: UUID!) {\n    galleryType(galleryTypeId: $galleryTypeId) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query galleryType($galleryTypeId: UUID!) {\n    galleryType(galleryTypeId: $galleryTypeId) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateGalleryType($galleryTypeId: UUID!, $data: UpdateGalleryTypeInput!) {\n    updateGalleryType(galleryTypeId: $galleryTypeId, data: $data) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  mutation updateGalleryType($galleryTypeId: UUID!, $data: UpdateGalleryTypeInput!) {\n    updateGalleryType(galleryTypeId: $galleryTypeId, data: $data) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query galleryTypes {\n    galleryTypes {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query galleryTypes {\n    galleryTypes {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteGalleryType($galleryTypeId: UUID!) {\n    deleteGalleryType(galleryTypeId: $galleryTypeId)\n  }\n"): (typeof documents)["\n  mutation deleteGalleryType($galleryTypeId: UUID!) {\n    deleteGalleryType(galleryTypeId: $galleryTypeId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation duplicateGallery($galleryId: UUID!) {\n    newGallery: duplicateGallery(galleryId: $galleryId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation duplicateGallery($galleryId: UUID!) {\n    newGallery: duplicateGallery(galleryId: $galleryId) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query galleries($page: Int!, $limit: Int!, $filters: GalleriesFiltersInput) {\n    galleries(page: $page, limit: $limit, filters: $filters) {\n      count\n      pagesCount\n      items {\n        id\n        name\n        startsAt\n        endsAt\n        capturesCount\n        removalDate\n        type {\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query galleries($page: Int!, $limit: Int!, $filters: GalleriesFiltersInput) {\n    galleries(page: $page, limit: $limit, filters: $filters) {\n      count\n      pagesCount\n      items {\n        id\n        name\n        startsAt\n        endsAt\n        capturesCount\n        removalDate\n        type {\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query galleryStatus($galleryId: UUID!) {\n    messagesSent: messagesCount(filters: { galleryId: $galleryId, status: SENT })\n    messagesError: messagesCount(filters: { galleryId: $galleryId, status: ERROR })\n    messagesWaiting: messagesCount(filters: { galleryId: $galleryId, status: WAITING })\n    capturesWaiting: capturesCount(filters: { galleryId: $galleryId, status: CREATED })\n    capturesError: capturesCount(filters: { galleryId: $galleryId, status: ERROR })\n    capturesProcessed: capturesCount(filters: { galleryId: $galleryId, status: PROCESSED })\n    captures: pageCaptures(page: 1, limit: 3, filters: { galleryId: $galleryId, status: PROCESSED }) {\n      items {\n        id\n        shortId\n        uploadFilename\n        status\n        deletedAt\n        file {\n          path\n        }\n        preview {\n          path\n        }\n        thumbnail {\n          path\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query galleryStatus($galleryId: UUID!) {\n    messagesSent: messagesCount(filters: { galleryId: $galleryId, status: SENT })\n    messagesError: messagesCount(filters: { galleryId: $galleryId, status: ERROR })\n    messagesWaiting: messagesCount(filters: { galleryId: $galleryId, status: WAITING })\n    capturesWaiting: capturesCount(filters: { galleryId: $galleryId, status: CREATED })\n    capturesError: capturesCount(filters: { galleryId: $galleryId, status: ERROR })\n    capturesProcessed: capturesCount(filters: { galleryId: $galleryId, status: PROCESSED })\n    captures: pageCaptures(page: 1, limit: 3, filters: { galleryId: $galleryId, status: PROCESSED }) {\n      items {\n        id\n        shortId\n        uploadFilename\n        status\n        deletedAt\n        file {\n          path\n        }\n        preview {\n          path\n        }\n        thumbnail {\n          path\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteGallery($galleryId: UUID!) {\n    deleteGallery(galleryId: $galleryId)\n  }\n"): (typeof documents)["\n  mutation deleteGallery($galleryId: UUID!) {\n    deleteGallery(galleryId: $galleryId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation restoreGallery($galleryId: UUID!) {\n    restoreGallery(galleryId: $galleryId)\n  }\n"): (typeof documents)["\n  mutation restoreGallery($galleryId: UUID!) {\n    restoreGallery(galleryId: $galleryId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query galleryId($gallerySlug: String!) {\n    galleryId(gallerySlug: $gallerySlug)\n  }\n"): (typeof documents)["\n  query galleryId($gallerySlug: String!) {\n    galleryId(gallerySlug: $gallerySlug)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query messages($page: Int!, $limit: Int!, $filters: MessagesFiltersInput) {\n    messages(page: $page, limit: $limit, filters: $filters) {\n      count\n      pagesCount\n      items {\n        id\n        contact\n        type\n        status\n        sentAt\n        errorMessage\n      }\n    }\n  }\n"): (typeof documents)["\n  query messages($page: Int!, $limit: Int!, $filters: MessagesFiltersInput) {\n    messages(page: $page, limit: $limit, filters: $filters) {\n      count\n      pagesCount\n      items {\n        id\n        contact\n        type\n        status\n        sentAt\n        errorMessage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query messagesWithCapture($page: Int!, $limit: Int!, $filters: MessagesFiltersInput) {\n    messages(page: $page, limit: $limit, filters: $filters) {\n      count\n      pagesCount\n      items {\n        id\n        contact\n        type\n        status\n        sentAt\n        errorMessage\n        capture {\n          id\n          uploadFilename\n          thumbnail {\n            path\n          }\n          gallery {\n            name\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query messagesWithCapture($page: Int!, $limit: Int!, $filters: MessagesFiltersInput) {\n    messages(page: $page, limit: $limit, filters: $filters) {\n      count\n      pagesCount\n      items {\n        id\n        contact\n        type\n        status\n        sentAt\n        errorMessage\n        capture {\n          id\n          uploadFilename\n          thumbnail {\n            path\n          }\n          gallery {\n            name\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query stats($galleryId: UUID!) {\n    stats(galleryId: $galleryId) {\n      gallery {\n        id\n        endsAt\n      }\n      analytics {\n        views {\n          total\n          unique\n        }\n        deviceViews {\n          mobile\n          desktop\n        }\n        actions {\n          shareLink\n          shareFile\n          download\n        }\n        topCaptures {\n          id\n          shortId\n          uploadFilename\n          status\n          file {\n            path\n          }\n          preview {\n            path\n          }\n          thumbnail {\n            path\n          }\n        }\n      }\n      captures {\n        count\n        marketingCount\n        hourlyCount {\n          date\n          count\n        }\n        peopleCount\n      }\n      external {\n        printsCount\n        potentialUsersCount\n      }\n    }\n  }\n"): (typeof documents)["\n  query stats($galleryId: UUID!) {\n    stats(galleryId: $galleryId) {\n      gallery {\n        id\n        endsAt\n      }\n      analytics {\n        views {\n          total\n          unique\n        }\n        deviceViews {\n          mobile\n          desktop\n        }\n        actions {\n          shareLink\n          shareFile\n          download\n        }\n        topCaptures {\n          id\n          shortId\n          uploadFilename\n          status\n          file {\n            path\n          }\n          preview {\n            path\n          }\n          thumbnail {\n            path\n          }\n        }\n      }\n      captures {\n        count\n        marketingCount\n        hourlyCount {\n          date\n          count\n        }\n        peopleCount\n      }\n      external {\n        printsCount\n        potentialUsersCount\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query emailPreview($data: EmailLayoutDataInput!) {\n    emailPreview(data: $data)\n  }\n"): (typeof documents)["\n  query emailPreview($data: EmailLayoutDataInput!) {\n    emailPreview(data: $data)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query senderStatus($senderName: String!) {\n    senderStatus(senderName: $senderName)\n  }\n"): (typeof documents)["\n  query senderStatus($senderName: String!) {\n    senderStatus(senderName: $senderName)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation addSender($senderName: String!) {\n    addSender(senderName: $senderName)\n  }\n"): (typeof documents)["\n  mutation addSender($senderName: String!) {\n    addSender(senderName: $senderName)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query users($filters: UsersFiltersInput) {\n    users(filters: $filters) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query users($filters: UsersFiltersInput) {\n    users(filters: $filters) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query currentUser {\n    currentUser {\n      id\n      email\n      name\n      role\n    }\n  }\n"): (typeof documents)["\n  query currentUser {\n    currentUser {\n      id\n      email\n      name\n      role\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation userLogout {\n    userLogout\n  }\n"): (typeof documents)["\n  mutation userLogout {\n    userLogout\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createGallery($data: CreateGalleryInput!) {\n    gallery: createGallery(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation createGallery($data: CreateGalleryInput!) {\n    gallery: createGallery(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation saveStatsConfig($galleryId: UUID!, $data: SaveStatsConfigInput!) {\n    saveStatsConfig(galleryId: $galleryId, data: $data)\n  }\n"): (typeof documents)["\n  mutation saveStatsConfig($galleryId: UUID!, $data: SaveStatsConfigInput!) {\n    saveStatsConfig(galleryId: $galleryId, data: $data)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query galleryBase($galleryId: UUID!) {\n    gallery(galleryId: $galleryId) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query galleryBase($galleryId: UUID!) {\n    gallery(galleryId: $galleryId) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query galleryConfig($galleryId: UUID!) {\n    gallery(galleryId: $galleryId) {\n      id\n      name\n      slug\n      thumbnailPosition\n      startsAt\n      endsAt\n      galleryTypeId\n      eventManagerId\n      techniciansIds\n      contacts\n    }\n    statsConfig(galleryId: $galleryId) {\n      isPeopleCounterActive\n    }\n  }\n"): (typeof documents)["\n  query galleryConfig($galleryId: UUID!) {\n    gallery(galleryId: $galleryId) {\n      id\n      name\n      slug\n      thumbnailPosition\n      startsAt\n      endsAt\n      galleryTypeId\n      eventManagerId\n      techniciansIds\n      contacts\n    }\n    statsConfig(galleryId: $galleryId) {\n      isPeopleCounterActive\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateGallery($galleryId: UUID!, $gallery: UpdateGalleryInput!, $statsConfig: SaveStatsConfigInput!) {\n    updateGallery(galleryId: $galleryId, data: $gallery) {\n      id\n      name\n    }\n    saveStatsConfig(galleryId: $galleryId, data: $statsConfig)\n  }\n"): (typeof documents)["\n  mutation updateGallery($galleryId: UUID!, $gallery: UpdateGalleryInput!, $statsConfig: SaveStatsConfigInput!) {\n    updateGallery(galleryId: $galleryId, data: $gallery) {\n      id\n      name\n    }\n    saveStatsConfig(galleryId: $galleryId, data: $statsConfig)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query gallery($galleryId: UUID!) {\n    gallery(galleryId: $galleryId) {\n      id\n      slug\n      password\n      startsAt\n      endsAt\n      capturesCount\n    }\n  }\n"): (typeof documents)["\n  query gallery($galleryId: UUID!) {\n    gallery(galleryId: $galleryId) {\n      id\n      slug\n      password\n      startsAt\n      endsAt\n      capturesCount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query template($galleryId: UUID!) {\n    template(galleryId: $galleryId) {\n      email {\n        customMjml {\n          id\n          mjml\n        }\n        subject\n        fromName\n        logoUrl\n        logoWidth\n        logoPadding\n        headerText\n        topText\n        bottomText\n        buttonText\n        textColor\n        color\n        bgColor\n        contentBgColor\n        showBottomImg\n      }\n      sms {\n        sender\n        template\n      }\n      qr {\n        logoUrl\n        logoWidth\n        headerText\n        inputText\n        buttonText\n        successText\n        textColor\n        color\n        bgColor\n        showBottomImg\n      }\n    }\n  }\n"): (typeof documents)["\n  query template($galleryId: UUID!) {\n    template(galleryId: $galleryId) {\n      email {\n        customMjml {\n          id\n          mjml\n        }\n        subject\n        fromName\n        logoUrl\n        logoWidth\n        logoPadding\n        headerText\n        topText\n        bottomText\n        buttonText\n        textColor\n        color\n        bgColor\n        contentBgColor\n        showBottomImg\n      }\n      sms {\n        sender\n        template\n      }\n      qr {\n        logoUrl\n        logoWidth\n        headerText\n        inputText\n        buttonText\n        successText\n        textColor\n        color\n        bgColor\n        showBottomImg\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation saveTemplate($galleryId: UUID!, $data: SaveTemplateInput!) {\n    saveTemplate(galleryId: $galleryId, data: $data)\n  }\n"): (typeof documents)["\n  mutation saveTemplate($galleryId: UUID!, $data: SaveTemplateInput!) {\n    saveTemplate(galleryId: $galleryId, data: $data)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation userSignin($email: String!, $password: String!) {\n    userSignin(email: $email, password: $password) {\n      accessToken\n    }\n  }\n"): (typeof documents)["\n  mutation userSignin($email: String!, $password: String!) {\n    userSignin(email: $email, password: $password) {\n      accessToken\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query inProgressGalleries {\n    inProgressGalleries {\n      id\n      name\n      startsAt\n      endsAt\n      capturesCount\n      type {\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query inProgressGalleries {\n    inProgressGalleries {\n      id\n      name\n      startsAt\n      endsAt\n      capturesCount\n      type {\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query qrTemplate($galleryId: UUID!) {\n    template(galleryId: $galleryId) {\n      qr {\n        logoUrl\n        logoWidth\n        headerText\n        inputText\n        buttonText\n        successText\n        textColor\n        color\n        bgColor\n        showBottomImg\n      }\n    }\n  }\n"): (typeof documents)["\n  query qrTemplate($galleryId: UUID!) {\n    template(galleryId: $galleryId) {\n      qr {\n        logoUrl\n        logoWidth\n        headerText\n        inputText\n        buttonText\n        successText\n        textColor\n        color\n        bgColor\n        showBottomImg\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation sendEmailByFilename($gallerySlug: String!, $uploadFilename: String!, $email: String!) {\n    sendEmailByFilename(gallerySlug: $gallerySlug, uploadFilename: $uploadFilename, email: $email) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation sendEmailByFilename($gallerySlug: String!, $uploadFilename: String!, $email: String!) {\n    sendEmailByFilename(gallerySlug: $gallerySlug, uploadFilename: $uploadFilename, email: $email) {\n      id\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;