import { Spinner } from '@app/layout/spinner';
import { LoadingViewWrapper } from './loading-view.styles';

export const LoadingView = () => {
  return (
    <LoadingViewWrapper>
      <Spinner />
    </LoadingViewWrapper>
  );
};
