import { Colors } from '@app/styles/types/colors.type';

type KindOptions = 'background' | 'color';

export const kinds = {
  primary: {
    background: 'primary',
    color: 'white',
  },
  secondary: {
    background: 'secondary',
    color: 'primary',
  },
  white: {
    background: 'white',
    color: 'black',
  },
  whitePrimary: {
    background: 'white',
    color: 'primary',
  },
  danger: {
    background: 'danger',
    color: 'white',
  },
  gray: {
    background: 'gray',
    color: 'white',
  },
} satisfies Record<string, Record<KindOptions, Colors>>;

export type Kind = keyof typeof kinds;
