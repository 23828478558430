import { css } from 'styled-components';
import { rotate } from './animations/rotate';

export const font = () => css`
  font-family: 'aktiv-grotesk', sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.2;
`;

export const afterSpinner = (size: number, color: string) => css`
  &::after {
    content: '';
    position: absolute;
    width: ${size}px;
    height: ${size}px;
    border: 1.5px solid ${color};
    border-top-color: transparent;
    border-radius: 50%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    animation: ${rotate} 0.8s infinite linear;
  }
`;
