import styled from 'styled-components';
import { afterSpinner } from '@app/styles/common';
import { fadeIn } from '@app/styles/animations/fade-in';
import { Colors } from '@app/styles/types/colors.type';

interface SpinnerProps {
  color?: Colors;
}

export const Spinner = styled.div<SpinnerProps>`
  position: relative;
  width: 50px;
  height: 50px;
  animation: ${fadeIn} 1s linear;
  animation-fill-mode: backwards;
  animation-delay: 0.5s;

  ${({ theme, color = 'primary' }) => afterSpinner(35, theme.colors[color])}
`;
