import styled, { DefaultTheme } from 'styled-components';

interface TextProps {
  color?: keyof DefaultTheme['colors'];
  size?: keyof DefaultTheme['fontSizes'];
  center?: boolean;
  weight?: number;
  lineHeight?: number;
}

export const Text = styled.div.withConfig({
  shouldForwardProp: (props) => !['color', 'size', 'center', 'weight', 'lineHeight'].includes(props),
})<TextProps>`
  margin: 0;
  padding: 0;
  color: ${({ theme, color = 'black' }) => theme.colors[color]};
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  font-size: ${({ theme, size = 'base' }) => theme.fontSizes[size]};
  font-weight: ${({ weight }) => weight || 'inherit'};
  line-height: ${({ lineHeight }) => lineHeight || 'inherit'};
  letter-spacing: 0.1px;
`;
