import { Colors } from '@app/styles/types/colors.type';
import styled, { css } from 'styled-components';

interface IconWrapperProps {
  $color?: Colors;
  $size: number;
}

export const IconWrapper = styled.span<IconWrapperProps>`
  display: block;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;

  & svg {
    display: block;
  }

  & svg * {
    ${({ theme, $color }) =>
      $color &&
      css`
        &[fill] {
          fill: ${theme.colors[$color]};
        }
        &[stroke] {
          stroke: ${theme.colors[$color]};
        }
      `};
  }
`;
