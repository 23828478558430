export type CODES =
  | 'UNKNOWN'
  | 'UNAUTHORIZED'
  | 'FORBIDDEN'
  | 'INVALID_CREDENTIALS'
  | 'NOT_FOUND'
  | 'BAD_USER_INPUT'
  | 'RELATION_ENTITY_ERROR'
  | 'UNIQUE_ENTITY_ERROR';

export const MESSAGES: Partial<Record<CODES, string>> = {
  BAD_USER_INPUT: 'Validation error, is data correct?',
  INVALID_CREDENTIALS: 'Invalid credentials',
  NOT_FOUND: 'Resource not found',
  UNAUTHORIZED: 'Unauthorized access',
  FORBIDDEN: 'Operation is not permitted',
};
