import { css } from 'styled-components';
import { font } from './common';

export const toastifyCss = css`
  .Toastify__toast-container {
    --toastify-color-light: ${({ theme }) => theme.colors.primary};
    --toastify-text-color-light: ${({ theme }) => theme.colors.white};
    top: 20vh;
    right: 0;
    ${font()}
  }

  .Toastify__toast {
    border-radius: 0;
  }

  .Toastify__toast-body {
    ${font()}
    font-size: 14px;
    line-height: 21px;
    padding: 20px;
  }
`;
