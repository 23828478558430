import { FC } from 'react';
import { ApolloError } from '@apollo/client';
import { getErrorCode } from '@app/utils/apollo-errors.util';
import { Button } from '@app/components/button';
import { Text } from '@app/layout/text';
import { ErrorViewWrapper } from './error-view.styles';

const getMessage = (error: unknown): string => {
  if (typeof error === 'string') return error;
  if (!(error instanceof ApolloError)) return 'Unknown error';
  if (error.networkError) return 'Connection error';

  switch (getErrorCode(error)) {
    case 'NOT_FOUND':
      return 'Resource not found';
    default:
      return 'Unknown server error';
  }
};

const canRetry = (error: unknown) => {
  if (typeof error === 'string') return true;
  if (!(error instanceof ApolloError)) return true;
  return !['NOT_FOUND'].includes(getErrorCode(error));
};

interface ErrorViewProps {
  error?: unknown;
  onRetry?: () => void;
}

export const ErrorView: FC<ErrorViewProps> = ({ error, onRetry }) => {
  const message = getMessage(error);
  const isRetryBtnVisible = canRetry(error) && !!onRetry;

  return (
    <ErrorViewWrapper>
      <Text color="danger">{message}</Text>
      {isRetryBtnVisible && (
        <Button size="small" onClick={() => onRetry()}>
          Try again
        </Button>
      )}
    </ErrorViewWrapper>
  );
};
