import { FC, HTMLProps } from 'react';
import * as icons from './icons.config';
import { Colors } from '@app/styles/types/colors.type';
import { IconWrapper } from './icon.styles';

export type IconName = keyof typeof icons;

export interface IconProps extends HTMLProps<HTMLSpanElement> {
  name: IconName;
  color?: Colors;
  size?: number;
}

export const Icon: FC<IconProps> = ({ name, color, size = 19, ...props }) => {
  const Component = icons[name];
  return (
    <IconWrapper $color={color} $size={size} {...props}>
      <Component width={size} height={size} />
    </IconWrapper>
  );
};
